import CalendarIcon from '../../../kit/icons/CalendarIcon';
import ClockIcon from '../../../kit/icons/ClockIcon';
import CodeIcon from '../../../kit/icons/CodeIcon';
import JSIcon from '../../../kit/icons/JSIcon';
import LocateIcon from '../../../kit/icons/LocateIcon';
import CardCheckIcon from '../../../kit/icons/CardCheckIcon';
import CardFreeIcon from '../../../kit/icons/CardFreeIcon';
import CardJSIcon from '../../../kit/icons/CardJSIcon';
import CardMessageIcon from '../../../kit/icons/CardMessageIcon';
import CardRubleIcon from '../../../kit/icons/CardRubleIcon';

const data = {
  seo: {
    title:
      'Курсы по веб-разработке JavaScript в Санкт-Петербурге | Эльбрус Буткемп',
    meta: [
      {
        property: null,
        name: 'description',
        content:
          'Мечтаете стать Fullstack-разработчиком на JavaScript и TypeScript? Школа программирования Эльбрус Буткемп запускает курс по веб-разработке с нуля! После обучения наши карьерные коучи помогут с трудоустройством. Записывайтесь прямо сейчас!',
      },
      { property: 'og:type', name: null, content: 'product' },
      {
        property: 'og:title',
        name: null,
        content:
          'Курсы по веб-разработке JavaScript в Санкт-Петербурге | Эльбрус Буткемп',
      },
      {
        property: 'og:description',
        name: null,
        content:
          'Мечтаете стать Fullstack-разработчиком на JavaScript и TypeScript? Школа программирования Эльбрус Буткемп запускает курс по веб-разработке с нуля! После обучения наши карьерные коучи помогут с трудоустройством. Записывайтесь прямо сейчас!',
      },
      {
        property: 'og:image',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/04.JPG',
      },
      {
        property: 'og:site_name',
        name: null,
        content: 'Эльбрус Буткемп',
      },
      { property: 'og:locale', name: null, content: 'ru_RU' },
      { property: 'fb:app_id', name: null, content: '581051622277135' },
      { property: 'twitter:card', name: null, content: 'summary_large_image' },
      {
        property: 'twitter:site',
        name: null,
        content: 'https://twitter.com/elbrus_bootcamp',
      },
      {
        property: 'twitter:image:src',
        name: null,
        content: 'https://elbrusboot.camp/images/campus/04.JPG',
      },
    ],
  },
  careerDescription: {
    title: 'Fullstack-разработчик',
    description: [
      'человек, который возлагает на себя ответственность за все этапы разработки веб-сервиса. Он принимает участие как в создании визуальной части интернет-ресурса, так и в реализации серверной. Создает с нуля веб-приложение — самостоятельно или при поддержке команды разработчиков. Full stack = Frontend + Backend.',
      'Frontend — это то, что вы видите на мониторе. Backend — это все что отвечает за логику «под капотом»',
    ],
  },
  schedule: {
    title: 'Расписание дня',
    subtitle: 'Примерное',
    list: [
      {
        time: '9:00 − 9:15',
        topic: 'утренний\nстендап',
        period: null,
        image: 'standup',
      },
      {
        time: '9:15 − 11:00',
        topic: 'лекция',
        period: null,
        image: null,
      },
      {
        time: '11:00 − 12:30',
        topic: 'Практика соло, в парах или в группах',
        period: null,
        image: 'coding',
      },
      {
        time: '12:30 − 13:15',
        topic: 'английский разговорный   клуб',
        period: null,
        image: null,
      },
      {
        time: '13:15 − 14:00',
        topic: 'Обед',
        period: null,
        image: null,
      },
      {
        time: '14:00 − 19:00',
        topic: 'Решение задач, работа над проектами',
        period: null,
        image: 'practice',
      },
      {
        time: '19:00 − 20:00',
        topic: 'приглашенный спикер/ pizza\u00A0day',
        period: null,
        image: null,
      },
    ],
  },
  tools: {
    title: {
      first: 'Инструменты',
      second: 'Вы изучите',
    },
    folder: 'js',
    list: [
      {
        image: 'express',
        isMobileHidden: false,
      },
      {
        image: 'nodejs',
        isMobileHidden: false,
      },
      {
        image: 'ts',
        isMobileHidden: false,
      },
      {
        image: 'postgresql',
        isMobileHidden: false,
      },
      {
        image: 'jest',
        isMobileHidden: false,
      },
      {
        image: 'react',
        isMobileHidden: false,
      },
      {
        image: 'githubcat',
        isMobileHidden: false,
      },
      {
        image: 'git',
        isMobileHidden: false,
      },
      {
        image: 'redux',
        isMobileHidden: false,
      },
      {
        image: 'docker',
        isMobileHidden: true,
      },
    ],
  },
  employmentDescription: {
    title: 'Трудоустройство',
    markedSubTitle:
      'Карьерные коучи помогают с резюме, консультируют по IT-рынку, делятся лайфхаками по трудоустройству и остаются на связи в течение года после выпуска.',
    statistic: {
      value: '93%',
      description: 'студентов находят работу за 6 месяцев',
      link: {
        url: 'https://elbrusbootcamp.online/job-report2024/',
        title: 'Исследование Эльбрус Буткемп 2024',
      },
    },
  },
  projects: {
    title: 'Реальные проекты',
    subtitle: 'В Вашем портфолио',
    data: {
      description:
        'Проекты в Эльбрус Буткемп — не симуляция настоящей работы, а заказы от компаний-партнеров, которые приглашают лучших студентов для дальнейшего сотрудничества. В процессе обучения на курсах JavaScript в Санкт-Петербурге вы разработаете 3 проекта, которые станут частью вашего профессионального портфолио:',
      points: [
        'Проект от заказчика',
        'Индивидуальный проект',
        'Выпускной проект',
      ],
      list: [
        {
          description:
            'TEAM8 — веб-приложение для поиска компаньонов для онлайн игр в Steam',
          preview: 'team8',
          video: '',
        },
        {
          description: 'GuestHouse — веб-приложение для гостевого дома в Анапе',
          preview: 'anapa',
          video: '',
        },
        {
          description:
            'Снежный — веб-приложение для горнолыжного курорта Снежный',
          preview: 'snowy',
          video: '',
        },
        {
          description:
            'Cpir App — веб-приложение для управление проектами инженерных изысканий',
          preview: 'cripapp',
          video: '',
        },
      ],
    },
  },
  instruction: {
    title: 'Как попасть в Эльбрус Буткемп',
    subtitle: 'Инструкция',
    steps: [
      {
        icon: CardCheckIcon,
        title: 'Познакомьтесь с\u00A0Эльбрус Буткемп',
        image: 'meetElbrus',
        preSubTitle: 'Посетите День открытых дверей:',
        subTitles: [
          '[Ближайший — =eventDate=](=eventLink=)<!--rehype:ymSubmitEventType=upcomingEvent-->. Расскажем об обучении и почему оно эффективно',
          'Задайте интересующие вопросы выпускникам. Они поделятся своим опытом обучения и трудоустройства в IT-компанию',
        ],
        button: 'перейти к расписанию встреч',
        eventFilter: 'MEETING',
        buttonLink: '/events/',
        buttonMetric: 'spbJSSchedule',
        freeStatus: CardFreeIcon,
        progress:
          'Понял формат буткемпа и получил ответы на интересующие вопросы',
        progressVal: 20,
      },
      {
        icon: CardJSIcon,
        title: 'Подготовьтесь к\u00A0интервью',
        image: 'examPreparation',
        subTitles: [
          'Зарегистрируйтесь в [личном кабинете](https://edu.elbrusboot.camp/?)<!--rehype:utm=true&target=_blank--> Эльбрус Буткемп и начните изучать JavaScript по [фирменным материалам →]()<!--rehype:modal=true&ymSubmitEventType=spbJSMaterials&ymSubmitExtraEventType=lead&program=JS\u0020Санкт-Петербург&leadAction=Подписка\u0020гайд&leadBlock=Как\u0020поступить&leadPage=JS\u0020Spb_Основная\u0020программа&eventName=Материалы\u0020для\u0020подготовки&tags=спб-->',
          'Посетите бесплатные Мастер-классы',
          'Решите контрольные задачи',
          'При необходимости запишитесь на подготовительную программу с ментором [“Погружение в JS” →](/js-intense/)<!--rehype:ymSubmitEventType=divetoJS-->',
        ],
        button: 'Посетить мастер-класс',
        eventFilter: 'MASTER_KLASS',
        buttonLink: '/events/',
        buttonMetric: 'spbJSMK',
        freeStatus: CardFreeIcon,
        progress:
          'Освоил основы JavaScript. Запрограммировал первые собственные функции',
        progressVal: 50,
      },
      {
        icon: CardMessageIcon,
        title: 'Пройдите техническое интервью',
        image: 'techInterview',
        subTitles: [
          'Выберите удобную дату техинтервью',
          'Решите 3-4 задачи от ментора по видеосвязи',
        ],
        button: 'Перейти в личный кабинет',
        eventFilter: null,
        buttonLink:
          'https://edu.elbrusboot.camp/?utm_source=elbrus&utm_medium=roadmap_jsspb&utm_campaign=button',
        target: '_blank',
        buttonMetric: 'visitLK',
        freeStatus: CardFreeIcon,
        progress: 'Забронировал место в Эльбрус Буткемп',
        progressVal: 75,
      },
      {
        icon: CardRubleIcon,
        title: 'Выберите дату старта обучения и варианты оплаты',
        image: 'startCourse',
        subTitles: [
          'Определитесь с удобной датой начала обучения',
          'Подпишите договор',
          'Выберите удобный [способ оплаты](/spb-js/#price)<!--rehype:ymSubmitEventType=spbJSPaymentMethods-->: рассрочка или единовременный платеж',
        ],
        button: 'посмотреть потоки обучения',
        eventFilter: null,
        buttonLink: '/spb-js/#streams',
        buttonMetric: 'spbJSStreams',
        freeStatus: null,
        progress:
          'Приступил к освоению востребованной профессии веб-разработчика',
        progressVal: 100,
      },
    ],
  },
  questions: {
    message: 'Готовы ответить на ваши вопросы',
    actions: {
      first: {
        title: 'Оставить заявку',
      },
    },
    ymEvents: {
      consultationFormSubmit: 'spbJSBottomConsultation',
    },
    myTargetEvents: {
      consultationFormSubmit: 'SpbJSBottomConsultation',
    },
  },
  greetings: {
    location: {
      type: 'offline',
      title: 'санкт-петербург',
    },
    duration: {
      number: '12',
      currency: 'недель',
    },
    type: 'основная программа',
    title: ['Веб-', 'разработчик'],
    features: [
      {
        icon: 'week',
        title: '12 недель',
      },
      {
        icon: 'time',
        title: '500 часов',
      },
    ],
    action: 'Получить консультацию',
    ymEvents: {
      signUpFormSubmit: 'spbJSTopSignUp',
      consultationFormSubmit: 'spbJSTopConsultation',
    },
    myTargetEvents: {
      signUpFormSubmit: 'SpbJSTopSignUp',
      consultationFormSubmit: 'SpbJSTopConsultation',
    },
  },
  payment: {
    program: 'Fullstack Javascript',
    programType: 'Основная программа',
    title: 'Стоимость обучения',
    subtitle: 'Fullstack Javascript',
    data: {
      description: [
        {
          content: 'Окупаемость обучения уже на 3-й месяц. ',
          link: {
            text: 'Данные',
            title: 'исследования Эльбрус Буткемп',
            url: 'https://elbrusbootcamp.online/job-report2024/',
          },
          icon: 'wallet',
        },
        {
          content:
            'Главная цель — трудоустройство.\n93% студентов находят работу в течение 6\u00a0месяцев',
          icon: 'star',
        },
      ],
    },
    ymEvents: {
      paymentFormSubmitCredit: 'spbJSCredit',
      paymentFormSubmitFullPrice: 'spbJSPriceFull',
    },
    myTargetEvents: {
      paymentFormSubmitCredit: 'SpbJSCredit',
      paymentFormSubmitFullPrice: 'SpbJSPriceFull',
    },
  },
  education: {
    title: 'В кампусе с преподавателем',
    subtitle: 'Обучение',
    data: {
      list: [
        'Слушаете живые лекции в классных аудиториях и\u00A0получаете обратную связь от преподавателя',
        'Проводите code-review с ментором',
        'Работаете над проектами от заказчика',
        'После обучения готовитесь к собеседованиям вместе с карьерным коучем',
      ],
    },
  },
  details: {
    title: 'Главное',
    subtitle: 'Детали курса',
    details: [
      {
        title: 'График',
        info: '9 часов в день по будням',

        Icon: CalendarIcon,
      },
      { title: 'Часы занятий', info: '9:00 - 19:00', Icon: ClockIcon },
      {
        title: 'Профессия после обучения',
        info: 'Fullstack-разработчик\nна JavaScript и TypeScript',
        Icon: JSIcon,
      },
      {
        title: 'Адрес кампуса',
        info: `Лиговский проспект 140, БЦ Эко−Стандарт, 7 этаж (м. Обводный канал)`,
        Icon: LocateIcon,
      },
    ],
  },
  teachers: {
    subtitle: 'наши',
    title: 'Преподаватели',
  },
  groups: {
    title: 'Потоки обучения',
    subtitle: 'актуальные',
    program: 'Fullstack Javascript',
    type: 'основная программа',
    ymEvents: [
      {
        groupsFormOpen: 'spbJSFirstCardFormOpen',
        groupsFormSubmit: 'spbJSFirstCardFormSent',
      },
      {
        groupsFormOpen: 'spbJSSecondCardFormOpen',
        groupsFormSubmit: 'spbJSSecondCardFormSent',
      },
    ],
    myTargetEvents: [
      {
        groupsFormOpen: 'SpbJSFirstCardFormOpen',
        groupsFormSubmit: 'SpbJSFirstCardFormSent',
      },
      {
        groupsFormOpen: 'SpbJSSecondCardFormOpen',
        groupsFormSubmit: 'SpbJSSecondCardFormSent',
      },
    ],
  },
  structure: {
    title: ['Программа обучения', 'JavaScript в Санкт-Петербурге'],
    minHeight: '193px',
    list: [
      {
        period: '1-3 неделя',
        phase: 'Фаза 0',
        description:
          'Терминал, Git, основы HTML/CSS, основы JavaScript. Занятость 6-8 часов в день в онлайн формате.',
        backgroundColor: 'kit.background.gray',
        marginTop: '0',
        minHeight: '138px',
      },
      {
        period: '4-6 неделя',
        phase: 'Фаза 1',
        description:
          'JavaScript, unit-тестирование, алгоритмы, асинхронность, ООП, знакомство с SQL и ORM.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-178px',
        minHeight: '138px',
      },
      {
        period: '7-9 неделя',
        phase: 'Фаза 2',
        description:
          'Back-end (Node.js, Express), SQL/ORM, REST API, React и JWT. Два проекта для портфолио.',
        backgroundColor: 'kit.background.darkgray',
        marginTop: '-178px',
        minHeight: '138px',
      },
      {
        period: '10-12 неделя',
        phase: 'Фаза 3',
        description:
          'TypeScript (React + Redux Toolkit). Связь front-end с back-end и БД. Docker на платформе Selectel.',
        backgroundColor: 'kit.background.lightpurple',
        marginTop: '-176px',
        minHeight: '138px',
      },
      {
        period: '5 дней',
        phase: 'Карьерная неделя',
        description:
          'Стратегия по поиску работы, составление резюме, подготовка к собеседованиям.',
        backgroundColor: 'kit.background.middlepurple',
        marginTop: '-174px',
        minHeight: '138px',
      },
    ],
    ymEvents: {
      programFormSubmit: 'spbJSProgram',
    },
    myTargetEvents: {
      programFormSubmit: 'SpbJSProgram',
    },
  },
  projectsAndReviews: {
    projects: {
      title: 'Реальные проекты',
      image: 'reviews/projectsTitle',
      ymEvent: 'spbJSProjects',
    },
    reviews: {
      title: 'Отзывы',
      image: 'reviews/reviewsTitle',
      ymEvent: 'spbJSComments',
    },
  },
  howDoesBootcampWork: {
    image: 'howBootcampWorks/500codingHours',
    pointsLists: [
      {
        title: 'Из чего состоит обучение',
        list: [
          'Живые лекции и обратная связь от преподавателя',
          'Код-ревью: проверка кода преподавателем',
          'Работа над проектами от заказчика',
          'Подготовка к собеседованиям вместе с карьерным коучем',
        ],
      },
      {
        title: 'Что получает выпускник',
        list: [
          'Профессия: разработчик JavaScript, TypeScript',
          'Портфолио на GitHub: 2 групповых, 1 индивидуальный и 10 учебных проектов',
          'Диплом о профессиональной переподготовке установленного образца',
          'Доступ к закрытому комьюнити разработчиков',
        ],
      },
    ],
  },
  graduates: {
    title: 'Выпускники',
    subtitle: 'наши',
    data: {
      people: [
        {
          photo: 'pospelov',
          fullName: 'Руслан Поспелов',
          content:
            'Процесс обучения незабываем! Сложные задачи, утренние стендапы, пятничные ревью, пицца в кампусе и настольные игры после трудной недели. Преподаватели и карьерные коучи помогали все время обучения и поиска работы, я выпустился в июне 2022 и в августе уже принял оффер. Теперь я backend developer в компании ROKY.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/ruslan_Dabro',
            },
          ],
        },
        {
          photo: 'antonova',
          fullName: 'Юлия Антонова',
          content:
            'Я училась в кампусе в Питере — здесь чувствуется атмосфера настоящей IT-компании: ты постоянно окружен умными людьми, стремящимися развиваться и превозмогать свои возможности. А на карьерной неделе впечатлила вера в нас и постоянная поддержка карьерных коучей даже после окончания обучения.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/juantonova',
            },
          ],
        },
        {
          photo: 'natalichev',
          fullName: 'Александр Наталичев',
          content:
            'Я выпустился из Эльбруса в 2023 году (учился в кампусе в Петербурге) и благодаря буткемпу избежал учебы в университете, параллельных переработок в Макдональдсе, стресса от сессии и обрел востребованную профессию фронтент-разработчика, которая мне нравится, новых друзей и уверенность в завтрашнем дне.',
          links: [
            {
              source: 'telegram',
              url: 'https://t.me/Mejskit',
            },
          ],
        },
      ],
    },
  },
  atmosphere: {
    title: 'Атмосфера в кампусе',
    description:
      'В кампусе есть все как для учебы и регулярной практики, так и для отдыха между занятиями: настольные игры; диван, чтобы восстановить силы; кухня и терраса для дополнительного вдохновения.',
    slides: [
      {
        image: 'spb-slide-1',
      },
      {
        image: 'spb-slide-2',
      },
      {
        image: 'spb-slide-3',
      },
      {
        image: 'spb-slide-4',
      },
      {
        image: 'spb-slide-5',
      },
      {
        image: 'spb-slide-6',
      },
      {
        image: 'spb-slide-7',
      },
    ],
  },
  examPrepare: {
    title: 'Как подготовиться к интервью?',
    preCourseTitle: 'Основы JavaScript•Онлайн',
    selfPrepareTitle: 'JavaScript с 0•Тренажер',
    description:
      'Подготовка к вступительному к интервью занимает от 2‑х до 4‑х недель. Вы можете подготовиться самостоятельно или с ментором в группе.',
    preCourse: {
      duration: '2 недели',
      hours: '20 часов',
      pointsList: [
        '10 живых занятий с ментором',
        'Индивидуальная обратная связь по задачам от ментора',
        'Поддержка в закрытом чате разработчиков',
      ],
      link: {
        url: '/javascript-basics/',
        title: 'Подробнее',
      },
      ymEvent: 'spbJSPreCourses',
      myTargetEvent: 'SpbJSPreCourses',
    },
    trainer: {
      headerText: 'Бесплатный курс',
      pointsList: [
        'Изучите базовый синтаксис',
        'Попрактикуетесь в решении задач',
        'Получите обратную связь по вашим знаниям',
      ],
      ymEvent: 'spbJSTrainer',
      myTargetEvent: 'SpbJSTrainer',
    },
  },
  entranceInterview: {
    title: 'Вступительное интервью в Эльбрус Буткемп',
    video: 'https://www.youtube.com/embed/7JRBhVAHrYM',
    list: [
      'Перед поступлением будущих программистов ждет техническое интервью. Это нужно, чтобы студенты поняли, с чем им предстоит работать в ближайшие месяцы и убедились в серьезности своих намерений.',
      'Формат: ответы на вопросы и решение задач на видеозвонке, 45 минут',
    ],
    ymEvent: 'spbJSVideoInterview',
    myTargetEvent: 'SpbJSVideoInterview',
  },
};

export default data;
